<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <h2>Olá {{ user.nome }}</h2>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-row>
          <v-col
            sm="12"
            md="4"
            offset-md="4"
          >
            <v-btn
              color="primary"
              class="mr-4"
              to="/promotor/revendedores"
              block
            >
              MEUS REVENDEDORES
            </v-btn>
          </v-col>

          <v-col
            sm="12"
            md="4"
          >
            <v-btn
              color="primary"
              class="mr-4"
              to="/promotor/pagamentos"
              block
              outlined
            >
              MEUS PAGAMENTOS
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <revendedor-cards
      :total-de-acertos="model.totalDeAcertos"
      :comissao-acerto="model.comissaoAcerto"
      :total-comissao-acerto="model.totalComissaoAcerto"
      :total-de-revendedores-consignado="model.totalDeRevendedoresConsignado"
      :comissao-consignado="model.comissaoConsignado"
      :total-comissao-consignado="model.totalComissaoConsignado"
      :meta-de-cadastros-consignados="model.metaDeCadastrosConsignados"
      :comissao-cadastros-consignados="model.comissaoCadastrosConsignados"
      :total-comissao-cadastros-consignados="model.totalComissaoCadastrosConsignados"
      :total-de-revendedores-catalogo="model.totalDeRevendedoresCatalogo"
      :comissao-catalogo="model.comissaoCatalogo"
      :total-comissao-catalogo="model.totalComissaoCatalogo"
    />
    <v-row>
      <v-col
        cols="12"
      >
        <v-card>
          <v-card-title>
            REVENDEDORES
            <v-spacer />
            <search @applyFilters="loadData" />
          </v-card-title>
          <v-card-text>
            <datagrid-clientes
              :items="model.revendedores"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import RevendedorCards from '../components/RevendedorCards'
  import DatagridClientes from '../components/DatagridClientes'
  import Search from '../components/Search'

  export default {
    components: {
      RevendedorCards,
      DatagridClientes,
      Search,
    },
    data: () => ({
      model: {
        revendedores: [],
        totalDeAcertos: 0,
        comissaoAcerto: 0,
        totalComissaoAcerto: 0,
        totalDeRevendedoresConsignado: 0,
        comissaoConsignado: 0,
        totalComissaoConsignado: 0,
        metaDeCadastrosConsignados: 0,
        comissaoCadastrosConsignados: 0,
        totalComissaoCadastrosConsignados: 0,
        totalDeRevendedoresCatalogo: 0,
        comissaoCatalogo: 0,
        totalComissaoCatalogo: 0,
      },
    }),
    computed: {
      ...mapGetters(['user']),
    },
    mounted () {
      this.cleanUpModel()
    },
    methods: {
      cleanUpModel () {
        this.model.revendedores = []
        this.model.totalDeAcertos = 0
        this.model.comissaoAcerto = 0
        this.model.totalComissaoAcerto = 0
        this.model.totalDeRevendedoresConsignado = 0
        this.model.comissaoConsignado = 0
        this.model.totalComissaoConsignado = 0
        this.model.metaDeCadastrosConsignados = 0
        this.model.comissaoCadastrosConsignados = 0
        this.model.totalComissaoCadastrosConsignados = 0
        this.model.totalDeRevendedoresCatalogo = 0
        this.model.comissaoCatalogo = 0
        this.model.totalComissaoCatalogo = 0

        this.loadData()
      },
      loadData (params) {
        this.$http.get('promotor/dashboard', { params })
          .then(({ data }) => {
            this.model.revendedores = data.revendedores

            this.model.totalDeAcertos = data.totalDeAcertos
            this.model.comissaoAcerto = data.comissaoAcerto
            this.model.totalComissaoAcerto = data.totalComissaoAcerto
            this.model.totalDeRevendedoresConsignado = data.totalDeRevendedoresConsignado
            this.model.comissaoConsignado = data.comissaoConsignado
            this.model.totalComissaoConsignado = data.totalComissaoConsignado
            this.model.metaDeCadastrosConsignados = data.metaDeCadastrosConsignados
            this.model.comissaoCadastrosConsignados = data.comissaoCadastrosConsignados
            this.model.totalComissaoCadastrosConsignados = data.totalComissaoCadastrosConsignados
            this.model.totalDeRevendedoresCatalogo = data.totalDeRevendedoresCatalogo
            this.model.comissaoCatalogo = data.comissaoCatalogo
            this.model.totalComissaoCatalogo = data.totalComissaoCatalogo
          })
      },
    },
  }
</script>

<style>
a {
  text-decoration: none;
  font-weight: bold;
}
</style>
